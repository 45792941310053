









































































































































































































































































import SignTemplate from "@/components/sign/SignTemplate.vue";
import RegisterUser from "@/components/sign/signup/steps/RegisterUser.vue";
import RegisterActivity from "@/components/sign/signup/steps/RegisterActivity.vue";
import RegisterActivityDetails from "@/components/sign/signup/steps/RegisterActivityDetails.vue";
import RegisterBankAccount from "@/components/sign/signup/steps/RegisterBankAccount.vue";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { TaxRegime, ProductsModel, UserTags } from "@edmp/api";
import { productsStore, coreStore, usersStore } from "@/store";
import { useInit } from "@/composables";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "Registration",
  components: {
    SignTemplate,
    RegisterActivity,
    RegisterUser,
    RegisterActivityDetails,
    RegisterBankAccount,
  },
  props: {
    registrationIncomplete: {
      type: String,
      require: true,
      default: "false",
    },
    type: {
      type: String as PropType<ProductsModel.Tags.LRP>,
      require: false,
    },
  },
  setup(props, context) {
    const stepBi: Ref<boolean> = ref(false);
    const currentStep: ComputedRef<number> = computed(() => {
      if (context.root.$route.name === "RegisterActivity") {
        stepBi.value = false;
        return 1;
      } else if (context.root.$route.name === "RegisterUser") {
        stepBi.value = false;
        return 2;
      } else if (context.root.$route.name === "RegisterActivityDetails") {
        stepBi.value = false;
        return 3;
      } else {
        return stepBi.value ? 5 : 4;
      }
    });
    const onBoardingType = ref(props.type);
    const activityType: Ref<"SCI_IR" | "SCI_IS" | "LMNP" | undefined> = ref();
    const activityRegistered: Ref<boolean | undefined> = ref(undefined);
    const getTaxRegimeFromTags = (tags: UserTags[]): TaxRegime | undefined => {
      if (tags.includes(UserTags.SCI_IR)) {
        return TaxRegime.IR_2072;
      }
      if (tags.includes(UserTags.SCI_IS)) {
        return TaxRegime.IS_2065;
      }
      if (tags.includes(UserTags.LMNP)) {
        return TaxRegime.LMNP_2031;
      }
      return undefined;
    };
    const taxRegime: Ref<TaxRegime | undefined> = ref(
      usersStore.loggedInUser.tags
        ? getTaxRegimeFromTags(usersStore.loggedInUser.tags)
        : undefined
    );

    watch(activityType, () => {
      if (activityType.value === "SCI_IR") {
        taxRegime.value = TaxRegime.IR_2072;
      }
      if (activityType.value === "SCI_IS") {
        taxRegime.value = TaxRegime.IS_2065;
      }
      if (activityType.value === "LMNP") {
        taxRegime.value = TaxRegime.LMNP_2031;
      }
    });
    const { initOnBoardingStep } = useInit();

    watch(currentStep, (val) => {
      if (!activityType.value && !taxRegime.value) {
        navigate(ROUTE_NAMES.RegisterActivity);
      }
      initOnBoardingStep(context, val);
    });

    function navigate(routeName: string): void {
      context.root.$router.push({ name: routeName });
    }
    const validateRegisterActivityRegistered = () => {
      activityRegistered.value = true;
      navigate(ROUTE_NAMES.RegisterUser);
    };
    const validateRegisterActivityNonRegistered = () => {
      activityRegistered.value = false;
      navigate(ROUTE_NAMES.RegisterUser);
    };
    const validateActivityDetails = () => {
      if (taxRegime.value === TaxRegime.LMNP_2031) {
        navigate(ROUTE_NAMES.Dashboard);
      } else {
        navigate(ROUTE_NAMES.RegisterBank);
      }
    };

    onBeforeMount(async () => {
      try {
        await coreStore.getConfig();
        await initOnBoardingStep(context, 0);
      } catch (err) {
        // redirect error page or modal error
      }
    });

    onMounted(() => {
      useCrisp().closeApp();
    });

    return {
      currentStep,
      navigate,
      product: computed(() => productsStore.currentProduct),
      stepBi,
      openArticleRegistration: () =>
        useCrisp().openArticle(ArticleEnum.REGISTER_USER_ACCOUNT),
      openArticleWhoIsOwnily: () =>
        useCrisp().openArticle(ArticleEnum.WHO_IS_OWNILY),
      activityType,
      TaxRegime,
      taxRegime,
      onBoardingType,
      activityRegistered,
      validateRegisterActivityRegistered,
      validateRegisterActivityNonRegistered,
      validateActivityDetails,
      ROUTE_NAMES,
    };
  },
});
