
































































































































































import { useEmail, usePassword } from "@/composables";
import { dispatchOnBoardingEvent, OnBoardingEventSteps } from "@/events";
import { VForm } from "@/models";
import { userAccountService, usersService } from "@/services";
import { coreStore, usersStore } from "@/store";
import { getUtm } from "@/utils";
import { TaxRegime, Utm, UserTags } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref,
  PropType,
} from "@vue/composition-api";

export default defineComponent({
  name: "RegisterUser",
  props: {
    step: {
      type: String,
      required: true,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: true,
    },
    activityRegistered: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String as PropType<UserTags>,
      require: false,
    },
  },
  setup(props, context) {
    const email: Ref<string> = ref("");
    const password: Ref<string> = ref("");
    const showPassword: Ref<boolean> = ref(false);
    const acceptationCGU: Ref<boolean> = ref(false);
    const optionMarket: Ref<boolean> = ref(false);
    const firstname: Ref<string> = ref("");
    const lastname: Ref<string> = ref("");
    const phone: Ref<string> = ref("");
    const validateInProgress: Ref<boolean> = ref(false);
    const error: Ref<boolean> = ref(false);
    const utm: ComputedRef<Utm> = computed(() =>
      getUtm(context.root.$route.query)
    );
    const taxRegime = computed(() => props.taxRegime);
    const taxRegimeString = computed(() => {
      switch (taxRegime.value) {
        case TaxRegime.IR_2072:
          return "IR";
        case TaxRegime.IS_2065:
          return "IS";
        case TaxRegime.LMNP_2031:
          return "LMNP";
        default:
          return "IR";
      }
    });
    const getTagFromTaxRegime = (taxRegime: TaxRegime): UserTags => {
      let result: UserTags;
      switch (taxRegime) {
        case TaxRegime.IR_2072:
          result = UserTags.SCI_IR;
          break;
        case TaxRegime.IS_2065:
          result = UserTags.SCI_IR;
          break;
        case TaxRegime.LMNP_2031:
          result = UserTags.LMNP;
          break;
      }
      return result;
    };
    const tags = computed<UserTags[]>(() => {
      const result: UserTags[] = [];
      if (taxRegime.value) {
        result.push(getTagFromTaxRegime(taxRegime.value));
      }
      if (props.type) {
        result.push(props.type);
      }
      return result;
    });

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      error.value = false;
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;
        try {
          await userAccountService
            .create({
              username: email.value,
              password: password.value,
            })
            .then(
              async () =>
                await userAccountService.login({
                  username: email.value,
                  password: password.value,
                })
            )
            .then(async () => {
              /// Create a User with same email
              const user = await usersService.updateUser({
                id: "me",
                email: email.value,
                newEmail: email.value, // To validate this email
                firstName: firstname.value,
                lastName: lastname.value,
                phone: phone.value,
                optinMarket: optionMarket.value,
                utm: utm.value,
                tags: tags.value,
              });
              dispatchOnBoardingEvent({
                step: OnBoardingEventSteps.USER_DETAILS,
                userId: user.id,
                taxRegime: taxRegimeString.value,
                activityRegistered: props.activityRegistered,
              });
              await usersService.acceptTos({
                id: "me",
                tos: {
                  version: coreStore.config.user.tosVersion,
                  accepted: acceptationCGU.value,
                },
              });
            })
            .then(() => {
              usersStore.fetchLoggedInUser();
              // Store UTM tracking
              coreStore.createUtm(utm.value);

              context.emit("validate");
            });
        } catch (err) {
          console.error(err);
          error.value = true;
          validateInProgress.value = false;
        }
      }
    }

    const phoneValid = computed({
      get: () => phone.value.toLowerCase().trim(),
      set: (value: string) => (phone.value = value.replace(/[^\d]/g, "")),
    });
    const { validEmailRule, emailLowerCase } = useEmail();
    const { validPasswordRule, getPasswordRule } = usePassword();

    async function openCGU(e: Event): Promise<void> {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      window.open(
        "https://www.ownily.fr/legal/conditions-generales-utilisation",
        "_blank"
      );
    }

    async function openPVP(e: Event): Promise<void> {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      window.open("https://www.ownily.fr/legal/politique-vie-privee", "_blank");
    }

    return {
      email,
      emailLowerCase: emailLowerCase(email),
      password,
      showPassword,
      acceptationCGU,
      optionMarket,
      firstname,
      lastname,
      phone,
      phoneValid,
      openCGU,
      openPVP,
      validEmailRule,
      validPasswordRule,
      getPasswordRule,
      validate,
      validateInProgress,
      error,
    };
  },
});
