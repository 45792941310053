import { Document, model, Schema } from "mongoose";
import { Bank, bankSchema } from "./Bank.model";
import { IsoDateAndTime, RequireField, Verified } from "./Common.model";
import {
  PartialField,
  Document as OUserDocument,
  UserStats,
  SubscriptionsModel,
  ProductsModel,
  BankAccount,
  AccessModel,
} from "..";
import { TaxRegime } from "./TaxRegime.enum";
/**
 * `UserId` — Unique Id for a User
 */
export type UserId = string;

export type Scope = "anonymous" | "member" | "support" | "external_app" | "admin" | "system";

// Term of service
export enum TosVersion {
  VERSION_1 = 20220112,
  VERSION_2 = 20220302,
}
export interface TosItem {
  version: TosVersion;
  accepted: boolean;
  acceptedAt?: IsoDateAndTime;
}
export const TosSchema = new Schema<TosItem>(
  {
    version: { type: Number, enum: TosVersion, require: true },
    accepted: { type: Boolean, require: true },
    acceptedAt: { type: Date, require: true },
  },
  {
    _id: false,
  }
);
export type Tos = TosItem[];

// UTM url tracking
export interface Utm {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
}

export const defaultUTM = { utmSource: "", utmMedium: "", utmCampaign: "", utmTerm: "", utmContent: "" };

export const UtmSchema = new Schema<Utm>(
  {
    utmSource: { type: String },
    utmMedium: { type: String },
    utmCampaign: { type: String },
    utmTerm: { type: String },
    utmContent: { type: String },
  },
  {
    _id: false,
  }
);

/*
 * `User` — An application user
 */
export enum UserTags {
  LRP = "LRP", // Onboarding type LRP (https://gitlab.com/edmp/fonctionnel/-/issues/2554)
  SCI_IR = "SCI_IR",
  SCI_IS = "SCI_IS",
  LMNP = "LMNP",
}

export type UserProduct<
  ProductType extends ProductsModel.ProductTypes = ProductsModel.ProductTypes,
  MyAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
  IsArchived extends boolean = boolean
> = ProductsModel.Product<ProductType, AccessModel.AccessTypes, IsArchived> & {
  myAccessType: MyAccessType;
};
export type UserBankAccount<
  MyAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
  IsArchived extends boolean = boolean
> = Pick<BankAccount<AccessModel.AccessTypes, IsArchived>, "id" | "accessUsers"> & {
  myAccessType: MyAccessType;
};

export interface User<
  MyProductAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
  MyBankAccountAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes
> {
  id: string;
  tos: Tos;
  suspensionAt?: IsoDateAndTime;
  earliestSyncAt?: IsoDateAndTime;
  banks?: Bank[];
  products: UserProduct<ProductsModel.ProductTypes, MyProductAccessType, false>[]; // ! Not in BDD
  productsArchived: UserProduct<ProductsModel.ProductTypes, AccessModel.AccessTypes, true>[]; // ! Not in BDD
  bankAccounts: UserBankAccount<MyBankAccountAccessType, false>[]; // ! Not in BDD
  bankAccountsArchived: UserBankAccount<AccessModel.AccessTypes, true>[]; // ! Not in BDD
  firstName: string;
  lastName: string;
  email: string;
  emailStatus: Verified;
  emailVerifyToken?: string;
  emailVerifyExpirationDate?: string;
  newEmail?: string | null;
  phone?: string;
  phoneStatus?: Verified;
  bi_user_id?: string;
  bi_user_token?: string;
  mock_connection?: boolean;
  bankReferences?: BankReference[];
  optinMarket: boolean;
  stripeCustomerId?: string;
  stripeActivePaymentMethodId?: string;
  utm?: Utm;

  tags: UserTags[];

  delete?: boolean; // Flag to delete a User - deprecated
  deletedAt?: IsoDateAndTime; // Flag to delete a User
  createdAt?: IsoDateAndTime;
  updatedAt?: IsoDateAndTime;
}
export type UserRepository = Omit<User, "products" | "productsArchived" | "bankAccounts" | "bankAccountsArchived">;

export type UserCreateOrUpdate = PartialField<
  Required<Pick<User, "id" | "firstName" | "lastName" | "email" | "newEmail" | "tags">>,
  "id" | "tags"
>;

export type UserUpdate = RequireField<
  Partial<
    Pick<
      User,
      | "id"
      | "firstName"
      | "lastName"
      | "email"
      | "newEmail"
      | "emailStatus"
      | "phone"
      | "optinMarket"
      | "utm"
      | "tags"
    >
  >,
  "id"
>;

/**
 * `MetaUser` — Metadata information about User corresponding to Json Web Token)
 */
export interface MetaUser {
  sub: string;
  scope: Scope;
  username: string;
  extended?: User;

  products: UserProduct<ProductsModel.ProductTypes, AccessModel.AccessTypes, false>[];
  bankAccounts: UserBankAccount<AccessModel.AccessTypes, false>[];
  subscriptions: SubscriptionsModel.Subscription[];

  iat?: string | undefined;
  exp?: string | undefined;
  act?: string;
}

export interface BankReference {
  _id?: string;
  userId?: string;
  productId: string;
  bankAccountId: string;
  source: string;
  lastSync?: IsoDateAndTime;
  bi_account_number?: string;
  bi_account_iban?: string;
  bi_account_original_name?: string;
  bi_account_type?: string;
  bi_account_id?: string;
  bi_connection?: string;
}

export type ReportDocument = User & { product: ProductsModel.Product } & {
  transaction: { count: number };
} & {
  lastTaxRegime: TaxRegime;
} & { lastLoginDate: string } & {
  subscription: SubscriptionsModel.Subscription;
} & { ownedCompanies: string[] };

export type StatsUserByOwnedProductClosureStatus =
  | {
      userId: string;
      userEmail: string;
      userLastLogin: string;
      productId: string;
      companyName: string;
      subscriptionPlanType: SubscriptionsModel.PlanType;
      subscriptionEndAt: string;
      transactionUnCategorizedNumber: number;
      [key: string]: string | number | boolean;
    }
  | { noData: "No data" };

/**
 * * BDD
 */
// Used internally to map accounts retrieved from BI etc. to internal bank accounts.
// It is used only used internally when retrieving data from an external provider.
const bankReferenceSchema = new Schema<BankReference>({
  userId: String,
  productId: { type: String, index: true },
  bankAccountId: { type: String, index: true },
  source: { type: String, index: true },
  lastSync: Date,
  bi_account_type: String,
  bi_account_id: { type: String, index: true },
  bi_connection: { type: String, index: true },
  bi_account_number: { type: String, index: true },
  bi_account_iban: { type: String, index: true },
  bi_account_original_name: { type: String, index: true },
});

const userSchema = new Schema<UserDocument>(
  {
    _id: { type: String },
    // tos: { version: String, acceptedAt: Date },
    tos: [TosSchema],
    suspensionAt: { type: Date },
    earliestSyncAt: { type: Date },
    banks: [bankSchema],
    bankReferences: [bankReferenceSchema],
    firstName: String,
    lastName: String,
    email: {
      type: String,
      required: false, // only required for connected users
      index: {
        unique: true,
        partialFilterExpression: { email: { $type: "string" } },
      },
      default: null,
    },
    newEmail: {
      type: String,
      required: false,
      index: {
        unique: true,
        partialFilterExpression: { newEmail: { $type: "string" } },
      },
      default: null,
    },
    emailStatus: { type: String, default: "pending" },
    emailVerifyToken: String,
    emailVerifyExpirationDate: String,
    phone: String,
    phoneStatus: String,

    bi_user_id: String,

    bi_user_token: String,

    mock_connection: Boolean,
    optinMarket: Boolean,
    utm: UtmSchema,

    tags: { type: [{ type: String }] },

    stripeCustomerId: String,
    stripeActivePaymentMethodId: String,
    delete: { type: Boolean, default: false },
    deletedAt: { type: Date },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: UserDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type UserDocument = UserRepository & Omit<Document<string>, "delete">;
export const UserModel = model<UserDocument>("User", userSchema, "Users");

/**
 * * API
 */
export namespace UsersService {
  export type ListIn = Partial<Pick<User, "email">> & { page: number; limit: number; sort?: Record<string, unknown> };
  export type ListOut = User[];

  export type ListProductIn = Pick<User, "id">;
  export type ListProductOut = ProductsModel.Product[];

  export type ListDocumentIn = Pick<User, "id">;
  export type ListDocumentOut = OUserDocument[];

  export type GetIn = Pick<User, "id"> | { id: "me" };
  export type GetOut = User;

  export type UpdateIn = UserUpdate;
  export type UpdateOut = User;

  export type ValidateEmailIn = { token: string };
  export type ValidateEmailOut = User;

  export type AcceptTosIn = Pick<User, "id"> & { tos: Omit<User["tos"][number], "acceptedAt"> };
  export type AcceptTosOut = User;

  export type ResumeIn = Pick<User, "id">;
  export type ResumeOut = User;

  export type SuspendIn = Pick<User, "id">;
  export type SuspendOut = User;

  export type ReportingIn = {
    name: "full" | "subscriptionIRDueDate10Day" | "last2Days";
    sendEmail?: boolean;
    jsonFormat?: boolean;
  };
  export type ReportingOut = ReportDocument[] | string;

  export type PurgeIn = never;
  export type PurgeOut = boolean;

  export type CountIn = never;
  export type CountOut = { count: number };

  export type NewAccountIn = { date?: string };
  export type NewAccountOut = RequireField<Omit<UserStats, "stockAccount">, "newAccount">;

  export type StockIn = { date?: string };
  export type StockOut = RequireField<Omit<UserStats, "newAccount">, "stockAccount">;

  export type GetUsersByOwnedProductClosureStatusIn = { exportType: "csv" | "json"; sendEmail?: boolean };
  export type GetUsersByOwnedProductClosureStatusOut = StatsUserByOwnedProductClosureStatus[] | string;
}

export namespace UsersLib {
  export const isUserProductType =
    <
      ProductType extends ProductsModel.ProductTypes,
      MyAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
      IsArchived extends boolean = boolean
    >(
      type: ProductType
    ) =>
    (
      product: UserProduct<ProductsModel.ProductTypes, MyAccessType, IsArchived>
    ): product is UserProduct<ProductType, MyAccessType, IsArchived> =>
      product.type === type;

  export const isMyProductAccessType =
    <
      ProductType extends ProductsModel.ProductTypes,
      MyAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
      IsArchived extends boolean = boolean
    >(
      myAccessType: MyAccessType
    ) =>
    (
      product: UserProduct<ProductType, AccessModel.AccessTypes, IsArchived>
    ): product is UserProduct<ProductType, MyAccessType, IsArchived> =>
      product.myAccessType === myAccessType;

  export const isArchivedProduct = <Product extends UserProduct>(
    product: Product
  ): product is Product & { isArchived: true } => !!product.isArchived;
  export const isNotArchivedProduct = <Product extends UserProduct>(
    product: Product
  ): product is Product & { isArchived?: false } => !product.isArchived;

  export const isMyBankAccountAccessType =
    <MyAccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes, IsArchived extends boolean = boolean>(
      myAccessType: MyAccessType
    ) =>
    (
      bankAccount: UserBankAccount<AccessModel.AccessTypes, IsArchived>
    ): bankAccount is UserBankAccount<MyAccessType, IsArchived> =>
      bankAccount.myAccessType === myAccessType;

  export const getProductIds = (user: User, withArchived?: boolean) => [
    ...user.products.map((p) => p.id),
    ...(withArchived ? user.productsArchived.map((p) => p.id) : []),
  ];

  export const getBankAccountIds = (user: User, withArchived?: boolean) => [
    ...user.bankAccounts.map((p) => p.id),
    ...(withArchived ? user.bankAccountsArchived.map((p) => p.id) : []),
  ];

  export const getProductIdsByMyProductAccessType = (user: User, accessType: AccessModel.AccessTypes) =>
    user.products.filter(isMyProductAccessType(accessType)).map((p) => p.id);

  export const getBanAccountIdsByMyBankAccountAccessType = (user: User, accessType: AccessModel.AccessTypes) =>
    user.bankAccounts.filter(isMyBankAccountAccessType(accessType)).map((b) => b.id);

  // export const getCompanyIdsByMyProductAccessType = (user: User, accessType: AccessModel.AccessTypes) =>
  //   user.products
  //     .filter(isUserProductType(ProductsModel.ProductTypes.SCI))
  //     .filter(isMyProductAccessType(accessType))
  //     .map((p) => p.company.id);
}
